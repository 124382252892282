import clsx from "clsx";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import s from "./navigation-new.module.css";

type Props = {
  title: string;
  items: Item[];
  logo?: string;
  className?: string;
};

type Item = {
  text: string;
  icon: string;
  href?: string;
  external?: boolean;
  hidden?: boolean;
  children?: any[];
  action?: () => void;
};

export function Navigation({ className, title, items, logo }: Props) {
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false)
  const itemContent = (item: Item) => {
    return (
      <>
      {item.icon ?
        <>
          <span className={s.icons}>
            <img width="28" height="29" src={item.icon} alt={item.text} />
          </span>
          <span className={s.link}>{item.text}</span>
          </>
      :
      <span className={s.childlink}>{item.text}</span>
      }
        
      </>
    );
  };
  const renderLink = (item: Item) => {
    if (item.external)
      return (
        <a className={s.item} href={item.href} target="_blank">
          {itemContent(item)}
        </a>
      );

    if (item.hidden) {
      return (
        <div className={clsx(s.item, s.itemHidden)}>{itemContent(item)}</div>
      );
    }

    if (item.children) {
      return (
        <>
        <div className={clsx(s.item)} onClick={() => setShowSubMenu((prevState) => !prevState)}>{itemContent(item)}
        {showSubMenu &&
          <i className={clsx(s.parent, s.open)}>
            <span className={clsx(s.parent_left)}></span>
            <span className={clsx(s.parent_right)}></span>
          </i>
        }
        {!showSubMenu &&
          <i className={clsx(s.parent)}>
            <span className={clsx(s.parent_left)}></span>
            <span className={clsx(s.parent_right)}></span>
          </i>
        }
        </div>

        {showSubMenu && item.children.map((item, key) => (
          <li key={key} className={s.child}>
            {item.href && renderLink(item)}
            {item.action && renderButton(item)}
          </li>
        ))}
        </>
      );
    }

    return (
      <NavLink
        // @ts-ignore
        to={item.href}
        className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
      >
        {itemContent(item)}
      </NavLink>
    );
  };
  const renderButton = (item: Item) => {
    return (
      <button type="button" onClick={item.action} className={s.item}>
        {itemContent(item)}
      </button>
    );
  };

  return (
    <nav className={clsx(className, s.root)}>
      <Link className={s.logo} to="/">
        {logo &&
        <img
          width="28"
          height="29"
          className={s.img}
          src={logo}
          alt="логотип"
        />}
        <h1 className={s.title}>{title}</h1>
      </Link>

      <ul className={s.list}>
        {items.map((item, key) => (
          <li key={key}>
            {item.href && renderLink(item)}
            {item.action && renderButton(item)}
          </li>
        ))}
      </ul>
    </nav>
  );
}
