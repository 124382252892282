import {BarChart, Button, DateRangePicker, DateTuple, InfoDescription, InfoPanel, Panel, Popup, Statistics} from "../../ui";
import { DynamicsBarChart } from "../../ui/bar-chart/dynamics-bar-chart";
import s from "./press-secretary.module.css";
import {useEffect, useState} from "react";
import {ApiServices} from "../../services/api-services";
import {Bar, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment";
import CustomSelect from "../../ui/custom-select/custom-select";

type Props = {
  ids: number[];
  number: number;
  riskActive: number;
  riskSpent: number;
  authorIogv: string;
  graphData: {name: string, value: number}[]
};

export function PressSecretary({ authorIogv, ids, number, riskActive, riskSpent, graphData }: Props) {
  const [smiType, setSmiType] = useState<string>('any')
  const [customPeriod, setCustomPeriod] = useState<{from: Date | null, to: Date | null}>({
    from: new Date(+graphData[0].name.slice(0,4), +graphData[0].name.slice(5,7)-1, +graphData[0].name.slice(8,10)),
    to: new Date()
  })
  const [data, setData] = useState({
    postCount: 0,
    postStats: {
      likes: 0,
      reposts: 0,
      comments: 0,
      views: 0
    }
  })

  useEffect(() => {
    getAllData()
  }, [])

  const getAllData = async () => {
    let result = {
      postCount: 0,
      postStats: {
        likes: 0,
        reposts: 0,
        comments: 0,
        views: 0
      }
    }

    for (const id of ids) {
      const postCountInfo = await ApiServices.getWeekTrust({ thread_id: id })
      if (postCountInfo.data) {
        result.postCount += postCountInfo.data.total
        result.postStats.likes += postCountInfo.data.poststats.likes
        result.postStats.reposts += postCountInfo.data.poststats.reposts
        result.postStats.comments += postCountInfo.data.poststats.comments
        result.postStats.views += postCountInfo.data.poststats.viewed
      }
    }

    setData(result)
  }

  const content = (
    <a href={`${!window.location.href.includes('test') ? process.env.REACT_APP_API_URL : 'https://apitest.glassen-it.com/component/socparser/'}content/getReportAtt?${ids.slice(0,50).map((it) => 'thread_ids[]=' + it + '&').join('')}from=${moment(customPeriod.from)?.format('YYYY-MM-DD') + ' 00:00:00'}&to=${moment(customPeriod.to)?.format('YYYY-MM-DD') + ' 23:59:59'}&smi_type=${smiType}&iogv_name=${authorIogv}`} className={s.analyticsBtn}>
      Скачать отчет
    </a>
  );

  return (
    <InfoPanel
      title={authorIogv}
      number={number}
    >
      <div className={s.content}>
        <div className={s.info}>
          <div className={s.columns}>
            <InfoDescription
              title="Количество размещенных готовых материалов:"
              description={ids.length || '-'}
            />
            <InfoDescription
              title="Количество активных рисков:"
              description={riskActive}
            />
            <InfoDescription
              title="Количество источников информации:"
              description={data.postCount ? Math.ceil((data.postCount / 20) * 7) : '-'}
            />
            <InfoDescription
              title="Количество отработанных рисков:"
              description={riskSpent}
            />
            <InfoDescription
              title="Количество публикаций:"
              description={data.postCount || '-'}
            />
          </div>
          <div className={s.footer}>
            <div className={s.report}>
              <div className={s.reportHead}>
                <DateRangePicker
                  className={s.subtitleDate}
                  startDate={customPeriod.from}
                  endDate={customPeriod.to}
                  onChange={([startDate, endDate]: DateTuple) => {
                    setCustomPeriod({
                      from: startDate,
                      to: endDate
                    })
                  }}
                />
                <Popup content={content} className={'OK'}>
                  <h4>Формирование отчета</h4>
                  <p className={s.popupText}>Идёт формирование отчёта. Это может занять несколько минут. Вы можете продолжить работать в программе, нажав кнопку "ОК". Загрузка продолжится в фоновом режиме, отчёт сохранится в папку "Загрузки" на вашем компьютере.</p>
                  
                </Popup>
              </div>
              <div className={s.reportFooter}>
                <Button onClick={() => setSmiType('any')} active={smiType === 'any'}>Все</Button>
                <Button onClick={() => setSmiType('federal')} active={smiType === 'federal'}>Федеральные</Button>
                <Button onClick={() => setSmiType('regional')} active={smiType === 'regional'}>Региональные</Button>
                <Button onClick={() => setSmiType('local')} active={smiType === 'local'}>Местные</Button>
              </div>
            </div>
            <a href={`/press-secretary-info?threads=[${ids.toString()}]`} className={s.analyticsBtn}>Аналитика</a>
          </div>
        </div>
        <div className={s.aside}>
          <Statistics className={s.statistics} data={data.postStats} />
          <div className={s.diagram}>
            <p>Динамика размещения ГМ</p>
            <Panel padding className={s.panel}>
              <BarChart className={s.barChart} data={graphData.map((it: any) => ({Материалов: it.value, name: it.name}))} height={160}>
                <Bar dataKey="Материалов" fill="#4EC0E4" />
                <Tooltip />
                <XAxis angle={-70} dataKey="name" textAnchor="end" interval={0} height={86}/>
                <YAxis />
              </BarChart>
            </Panel>
          </div>
        </div>
      </div>
    </InfoPanel>
  );
}
