import React from "react";
import clsx from "clsx";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import calendarIcon from "./img/calendar.svg";
import s from "./date-range-picker.module.css";
import "./date-range-picker.css";

export type DateTuple = [Date | null, Date | null];

type Props = {
  className?: string;
  startDate: Date | null;
  endDate: Date | null;
  onChange: (range: DateTuple) => void;
};

registerLocale("ru", ru);
setDefaultLocale("ru");

export const DateRangePicker = (props: Props) => {
  const { className, onChange, startDate, endDate } = props;

  return (
    <div className={clsx(className, s.picker)}>
      <DatePicker
        className={s.rangePicker}
        popperClassName={s.popper}
        clearButtonClassName={s.clearBtn}
        locale="ru"
        dateFormat="dd/MM/yyyy"
        popperPlacement="bottom"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        placeholderText="Выберите даты"
        isClearable={true}
        onChange={onChange}
      />
      <div className={s.calendar_icon}>
        <img src={calendarIcon} alt="" width="16" height="19.4" />
      </div>
    </div>
  );
};
