import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import clsx from "clsx";
import { Loader } from "../loader";
import s from "./api-info.module.css";

type ErrorType = FetchBaseQueryError | SerializedError;
type ErrorData = {
  code: number;
  error?: string;
  errors?: string[];
};
type Props = {
  isLoading: boolean;
  isError: boolean;
  error?: ErrorType;
  loaderClassName?: string;
  errorClassName?: string;
};

export const ApiInfo = (props: Props) => {
  const { isLoading, isError, error, loaderClassName, errorClassName } = props;

  if (isLoading) {
    return (
      <div className={clsx(s.loaderContainer, loaderClassName)}>
        <Loader />
      </div>
    );
  }

  if (isError) {
    let type = "Unknown";
    let message = "Something went wrong…";
    if (error && "name" in error) {
      type = error.name ?? type;
      message = error.message ?? message;
    }

    if (error && "data" in error && error.data) {
      const data = error.data as ErrorData;
      type = data?.code?.toString() ?? type;
      if (data.error) message = JSON.stringify(data.error || data.errors);
      if (data.errors) message = data.errors.join("\n");
    }
    return (
      <p className={clsx(s.errorContainer, errorClassName)}>
        <b>{type}</b>: {message}
      </p>
    );
  }

  return null;
};
