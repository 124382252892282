import type { ReactNode } from "react";
import { Navigation } from "./navigation";
import s from "./layout.module.css";

type Props = {
  children: ReactNode;
};

export function Layout({ children }: Props) {
  return (
    <div className={s.layout}>
      <Navigation className={s.navigation} />
      <div className={s.content}>{children}</div>
    </div>
  );
}
