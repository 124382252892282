import {toLocaleDateTime, useRiskItemQuery, useGetAllPostsQuery} from "../../features";
import { ApiInfo, InfoDescription, InfoPanel, Panel } from "../../ui";
import s from "./risk.module.css";
import {useEffect, useState} from "react";
import {ApiServices} from "../../services/api-services";
import {StatisticsExtended} from "../../ui/statistics-extended/statistics-extended";

type Props = {
  title: string;
  id: number;
  number: number;
  details: any;
};

export function Risk({ title, id, number, details }: Props) {
  const [currentStats, setCurrentStats] = useState<{smi: any, social: any}>({
    smi: null,
    social: null
  })
  const allPosts = useGetAllPostsQuery({})

  useEffect(() => {
    if (details.source_id?.length > 0) {
      getStatsData(details.source_id)
    }
  }, [])

  const getStatsData = async (ids: number[]) => {
    let tempStats: {smi: any, social: any} = {
      smi: null,
      social: null
    }

    for (const sourceType of ['smi', 'social']) {
      let isEmpty = true
      let currentTypeStats = {
        attendance: 0,
        comments_count: 0,
        federal: 0,
        federal_attendance: 0,
        likes_count: 0,
        local: 0,
        local_attendance: 0,
        negative: 0,
        netural: 0,
        positive: 0,
        post_count: 0,
        regional: 0,
        regional_attendance: 0,
        reposts_count: 0,
        viewed: 0,
      }
      const statsKeys = Object.keys(currentTypeStats)
      for (const threadId of ids) {
        const statsResponse = await ApiServices.getThreadStats({
          thread_id: threadId,
          type: sourceType,
          period: 'week'
        })
        if (statsResponse.data?.stats) {
          isEmpty = false
          statsKeys.forEach((it: string) => {
            // @ts-ignore
            currentTypeStats[it] += statsResponse.data.stats[it]
          })
        }

      }
      if (!isEmpty) {
        // @ts-ignore
        tempStats[sourceType] = { ...currentTypeStats }
      }
    }
    setCurrentStats(tempStats)
  }
  if (!allPosts.isSuccess) {
    return (
      <InfoPanel title="" number={number}>
        <ApiInfo isLoading={allPosts.isLoading} isError={false} error={undefined} />
      </InfoPanel>
    );
  }

  return (
    <InfoPanel
      title={title}
      number={number}
      statistics={{ publications: 0, status: "–" }}
    >
      <div className={s.statsInfo}>
        {currentStats.smi || currentStats.social
          ? <StatisticsExtended data={currentStats} /> : null
        }
      </div>
      <InfoDescription
        className={s.description}
        title="Отработка Риска:"
        description={details?.workout || '–'}
      />

      <div className={s.columns}>
        {details?.status &&
          <InfoDescription
            // className={s.status}
            title="Статус"
            description={details?.status || '–'}
          />
        }
        <InfoDescription
          // className={s.description}
          title="Ссылка:"
          description={details?.url}
        />
        <InfoDescription
          // className={s.description}
          title="Количество готовых материалов:"
          description={details?.source_id?.length}
        />
        <InfoDescription
          title="Целевая аудитория:"
          description={details?.ca_id?.map((it: any, idx: number) => {
            if (idx < details?.ca_id?.length - 1) {
              return it.title + ', '
            }
            return it.title
          })}
        />
        <InfoDescription
          title="Бюджет/Ресурсы:"
          description={details?.budget || '–'}
        />
        <InfoDescription
          title="Отвественный:"
          description={details?.responsible_id?.title || "–"}
        />
        <InfoDescription title="Соисполнители:" description={details?.coop_id?.length > 0 ? details?.coop_id?.map((it: any, idx: number) => {
          if (idx < details?.coop_id?.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "–"}/>
        <InfoDescription title="Участники:" description={details?.member_id?.length > 0 ? details?.member_id?.map((it: any, idx: number) => {
          if (idx < details?.member_id?.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "–"}/>
        <InfoDescription
          title="Локация:"
          description={details?.location_id?.title}
        />
        <InfoDescription
          title="Дата/время начала:"
          description={toLocaleDateTime(details?.date_start)}
        />
        <InfoDescription
          title="Дата/время окончания:"
          description={toLocaleDateTime(details?.date_finish)}
        />
        <InfoDescription
          title="ИОГВ:"
          description={(details?.iogv_id?.title)}
        />
        <InfoDescription
          title="Готовый материал:"
          description={details?.source_id?.length > 0 ? (details?.source_id?.map((it:any)=><a key={it} href={'/materials/'+ it}>{allPosts?.data?.filter((item:any)=>item.id==it)[0]?.threadname ? allPosts?.data?.filter((item:any)=>item.id==it)[0]?.threadname :   it +' '}</a>)) : "-"}
        />
      </div>
    </InfoPanel>
  );
}
