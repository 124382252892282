import clsx from "clsx";
import { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import {
  useThreadsQuery,
  useTopicsQuery,
  useRefSourcesQuery,
  useSmiQuery,
  mergeQueries,
  useMainTopicQuery,
  useStatsQuery,
} from "../../features";
import { useAppSelector } from "../../redux";
import {
  Button,
  PageTitle,
  Panel,
  ApiInfo,
  NoData,
  Topic,
  Field,
  Select,
  DateRangePicker,
  Checkbox,
} from "../../ui";
import s from "./topics.module.css";
import CustomSelect from "../../ui/custom-select/custom-select";
import moment from "moment";
import { ApiServices } from "../../services/api-services";

export const Topics = () => {
  type StateType = "subject" | "event" | "smi" | "main";
  type PeriodType = "day" | "week" | "month" | "custom";
  const [type, setType] = useState<StateType>("main");
  const [period, setPeriod] = useState<PeriodType>("day");
  const [searchString, setSearchString] = useState("");
  const [onlyFederal, setOnlyFederal] = useState(false);
  const [items, setItems] = useState<Array<any>>([])
  const [detailedItems, setDetailedItems] = useState<Array<any>>([])
  const [filteredData, setFilteredData] = useState([] as any[]);
  const [sourcesList, setSourcesList] = useState([] as any[]);
  const [sourcesListIsLoading, setSourcesListIsLoading] = useState(false);

  const [filterInProgress, setFilterInProgress] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(true)

  // const [mainItems, setMainItems] = useState([] as any[]);
  const [precached, setPrecached] = useState<boolean>(true);
  const [currentSourceId, setCurrentSourceId] = useState<string>("");
  const [sourse, setSourse] = useState<string>("");
  const myArray = {id : 'all', keyword : 'Любой'}
  const [listSourse, setListSourse] = useState([]);
  // const [itemFederal, setItemFederal] = useState([]);
  const [profileID, setProfileID] = useState<string>("");
  const [networkID, setNetworkID] = useState<string>("");
  const [userFilter, setUserFilter] =
    useState<{ network_id: number; profile_id: string } | null>(null);


  // const  updateData = (value: any) => {
  //   let listOfSourse: any = listSourse;
  //   value?.map((item: any) => {
  //     if (listOfSourse.find((it: { id: any; })=>it.id==item.id)==undefined)
  //     listOfSourse?.push(item)
  //   })
  //   listOfSourse[0]=myArray
  //
  //   setListSourse(listOfSourse)
  // }

  const { REACT_APP_EVENTS_ID, REACT_APP_SUBJECTS_ID, REACT_APP_THREAD_ID } =
    process.env;

  const thread_id = +REACT_APP_THREAD_ID!;
  const reference_id =
    type === "event" ? REACT_APP_EVENTS_ID : REACT_APP_SUBJECTS_ID;

  const user_id = useAppSelector((state) => state.auth.userId);
  const [referenceIds, setReferenceIds] = useState<string | number>("");
  const statsQuery = useStatsQuery("");
  const [typeId, setTypeId] = useState<string>(
    process.env.REACT_APP_SUBJECTS_ID as string
  );
  const findType = (item: any) => item.id === +typeId;
  const activeTypeItems = statsQuery.data?.find(findType)?.items ?? [];
  const [customPeriod, setCustomPeriod] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: new Date(),
    to: new Date(),
  });

  // I have no idea why, but we have to pass all of this
  // to the api (even if it works without)
  const wtfHardCodedParams = {
    reference_item_id: 0,
    user_id,
    from:
      period === "custom" && moment(customPeriod.to)
        ? moment(customPeriod.from)?.format("YYYY-MM-DD HH:MM")
        : undefined,
    to:
      period === "custom" && moment(customPeriod.to)
        ? moment(customPeriod.to)?.format("YYYY-MM-DD HH:MM")
        : undefined,
    user_filter: userFilter,
  };

  // useEffect(() => {
  //   getSourceList()
  // }, [])
  //
  // const getSourceList = async () => {
  //   if (referenceIds) {
  //     const refSourcesResponse = await ApiServices.getRefSources({
  //       thread_id: thread_id,
  //       referenceFilter: referenceIds ? [referenceIds] : [],
  //       network_id: type == "main" ? [1, 2, 3, 4, 5, 7, 8, 9] : [4],
  //       start: 0,
  //       limit: 10000,
  //     })
  //
  //     if (refSourcesResponse?.data?.items) {
  //       setSourcesList(refSourcesResponse?.data?.items)
  //     }
  //     setSourcesListIsLoading(false)
  //   }
  // }

  useEffect(() => {
    if (referenceIds) {
      setDataIsLoading(true)
      if (type === "main") getAllMainData();
      if (type === "smi") getAllSmiData();
      if (type !== "main" && type !== 'smi') getAllTopicsData();
    }
  }, [type, referenceIds, period, customPeriod]);

  const getAllMainData = async () => {
    const baseMainParams = {
      start: 0,
      limit: 20,
      thread_id: String(thread_id),
      period: period !== "custom" ? period : "",
      referenceFilter: referenceIds ? [referenceIds] : [],
      ...wtfHardCodedParams,
    }

    const mainSmiTopics = await ApiServices.getMainTopics({ ...baseMainParams, type: 'smi' });
    const mainSocialTopics = await ApiServices.getMainTopics({ ...baseMainParams, type: 'social' });

    let mainTopicList: any = [];
    if (mainSmiTopics.data?.items) {
      mainSmiTopics?.data?.items?.map((smiTopic: any) => {
        mainTopicList.push({
          ...smiTopic,
        });
      });
    }
    if (mainSocialTopics.data?.items) {
      mainSocialTopics?.data?.items?.map((socialTopic: any) => {
        mainTopicList.push({
          ...socialTopic,
        });
      });
    }

    setItems(mainTopicList);
  };

  const getAllSmiData = async () => {
    const itemsResponse = await ApiServices.getCurrentSmi()

    if (itemsResponse?.data) {
      setItems(itemsResponse?.data)
    }
  }

  const getAllTopicsData = async () => {
    const topicsParams = {
      type: "smi",
      start: 0,
      limit: 100,
      thread_id,
      reference_id,
      period: period !== "custom" ? period : "",
      precached,
      ...wtfHardCodedParams,
    };

    const itemsResponse = await ApiServices.getSourceTopic(topicsParams)

    if (itemsResponse?.data) {
      setItems(itemsResponse?.data?.items)
    }
  }

  const changeSourse = (value: any) => {
    if (value !== "") {
      setSourse(value);
      setProfileID(value);
      setNetworkID(value.split(",")[2]);
    }
  };

  const typeHandle = (value: StateType) => {
    setType(value);
    setProfileID("");
    setSourse("");
    setPeriod('day')
  }

  useEffect(() => {
    if (currentSourceId) {
      const currentSource = sourcesList.find(
        (it: any) => it.inner_id === currentSourceId
      );
      if (currentSource) {
        setUserFilter({
          network_id: currentSource.network_id,
          profile_id: currentSource.inner_id,
        });
      }
    } else {
      setUserFilter(null);
    }
  }, [currentSourceId]);

  useEffect(() => {
    setFilterInProgress(true)
    if (detailedItems) {
      let tempItems = [...detailedItems].sort((a: any, b: any) => a.attendance < b.attendance ? 1 : -1);
      if (searchString) {
        tempItems = [...tempItems].filter((it: { title: string }) => it.title.toLowerCase().includes(searchString.toLowerCase()));
      }
      if (onlyFederal) {
        tempItems = [...tempItems].filter((it: { isFederal: string }) => it.isFederal);
      }
      setFilteredData(tempItems);
    }
    setFilterInProgress(false)
  }, [onlyFederal, detailedItems, searchString]);

  useEffect(() => {
    setListSourse([])
    if (items.length > 0) {
      getAllTopicsItems()
    } else {
      setDetailedItems([])
      setDataIsLoading(false)
    }
  }, [items])

  useEffect(() => {
    if (activeTypeItems && activeTypeItems?.length > 0) {
      if (typeId === process.env.REACT_APP_SUBJECTS_ID) {
        setReferenceIds(activeTypeItems[0].id);
      } else {
        setReferenceIds("");
      }
    } else {
      setReferenceIds("");
    }
  }, [typeId, activeTypeItems]);

  const getAllTopicsItems = async () => {
    let dateStart = new Date();
    let dateFinish = period == 'month' ? dateStart.setMonth(dateStart.getMonth() - 1) : period == 'week' ? dateStart.setDate(dateStart.getDate() - 6) : period == 'day' ? dateStart.setHours(dateStart.getHours() - 24) : dateStart;  
    setDataIsLoading(true)
    const itemsPromises: any[] = []
    for (const item of items) {
      itemsPromises.push(ApiServices.getTopic({
        id: item.id?.length > 10 ? item.id : item.group_id,
        type: item.url === '' ? 'social' : 'smi',
        referenceFilter: [parseInt(reference_id!)],
        from: moment(period == 'custom' ? customPeriod.from : dateStart)?.format("YYYY-MM-DD") + " 00:00:00",
        to: moment(period == 'custom' ? customPeriod.to : new Date())?.format('YYYY-MM-DD') + ' 23:59:59'
      }))
    }
    const tempItems: any[] = []
    Promise.all(itemsPromises).then(result => {
      result.forEach((it, idx) => {
        const isFederal = it?.data?.items?.filter((it: any) => it.smi_type === 'federal').length > 0
        tempItems.push(({
          ...items[idx],
          isFederal,
          details: it.data
        }))
      })

      if (type === 'main') {
        let listOfSourse: any = [myArray];
        console.log('listOfSourse 1', listOfSourse)
        tempItems?.map((item: any) => {
          console.log('item', item)
          const sources = item?.details?.items?.map((it: { from_id: any; author: any; }) => ({
              id: it.from_id.toString(),
              keyword: it.author,
            })
          );
          sources?.map((source: any) => {
            if (!listOfSourse.find((it: { id: any }) => it.id == source.id)) {
              listOfSourse.push(source)
            }
          })
        })
        console.log('listOfSourse 2', listOfSourse)

        setListSourse(listOfSourse)
      }


      setDetailedItems(tempItems)
      setDataIsLoading(false)
    })
  }

  const searchSubmit = (value: string) => {
    setSearchString(value);
  };

  const newPeriod = (value: PeriodType) => {
    setPeriod(value);
    if (value !== "custom") setPrecached(true);
    else setPrecached(false);
  };

  const federalHandle = () => {
    setOnlyFederal((prevState) => !prevState);
  };

  const referenceIdsHandle = (value: string | number): void => {
    setReferenceIds(value);
  };

  const typeIdHandle = (value: string | number): void => {
    setTypeId(String(value));
  };

  return (
    <>
      <PageTitle>Темы дня: СМИ и Соц сети</PageTitle>
      {/*<PageTitle>Темы дня: СМИ и соцсети</PageTitle>*/}
      <Panel className={s.filter} padding>
        <div className={s.filterLine}>
          <Field
            placeholder="Поиск"
            icon="search"
            className={s.search}
            iconClickHandle={searchSubmit}
          />
          <Button
            className={s.tab}
            active={type === "main"}
            onClick={() => typeHandle('main')}
          >
            Список тем
          </Button>
          <div className={clsx(s.resonans, s[type])} onClick={() => typeHandle('subject')}>
            <div className={s.restheme}>Резонансные темы</div>
            <div className={s.checkboxes}>
              <label htmlFor="subject" className={s.expandLabel}>
                Cубъекты
              </label>
              <Checkbox
                id="subject"
                className={s.tab}
                checked={type === "subject"}
                onChange={() => typeHandle('subject')}
              />
              {'/'}
              <label htmlFor="event" className={s.expandLabel}>
                Cобытия
              </label>
              <Checkbox
                id="event"
                className={s.tab}
                checked={type === "event"}
                onChange={() => typeHandle('event')}
              />
            </div>
          </div>
          <Button
            className={s.tab}
            active={type === "smi"}
            onClick={() => typeHandle('smi')}
          >
            Новости Спб
          </Button>

          <Button
            className={s.tab}
            active={onlyFederal}
            onClick={federalHandle}
          >
            Федеральная повестка
          </Button>
        </div>
        <div className={s.filterLine}>
          {type !== "smi" &&
            (type === "main" ? (
              <>
                <label className={s.range}>
                  <span className={s.label}>Период</span>
                  <CustomSelect
                    items={[
                      { id: "day", keyword: "День" },
                      { id: "week", keyword: "Неделя" },
                      { id: "month", keyword: "Месяц" },
                      { id: "custom", keyword: "Период" },
                    ]}
                    onChange={(value) => newPeriod(value as PeriodType)}
                    value={period}
                    type="interval"
                  />
                </label>

                {period === "custom" ? (
                  <DateRangePicker
                    className={s.subtitleDate}
                    startDate={customPeriod.from}
                    endDate={customPeriod.to}
                    onChange={([startDate, endDate]) => {
                      setCustomPeriod({
                        from: startDate,
                        to: endDate,
                      });
                    }}
                  />
                ) : null}
              </>
            ) : (
              <label className={s.range}>
                <span className={s.label}>Период</span>
                <CustomSelect
                  items={[
                    { id: "day", keyword: "День" },
                    { id: "week", keyword: "Неделя" },
                    { id: "month", keyword: "Месяц" },
                  ]}
                  onChange={(value) => newPeriod(value as PeriodType)}
                  value={period}
                  type="interval"
                />
              </label>
            ))}
          {type === "main" && (
            <>
              <label className={s.range}>
                <span className={s.label}>Тип:</span>
                <CustomSelect
                  items={[
                    {
                      id: process.env.REACT_APP_SUBJECTS_ID!,
                      keyword: "Субъект",
                    },
                    {
                      id: process.env.REACT_APP_EVENTS_ID!,
                      keyword: "Событие",
                    },
                  ]}
                  onChange={typeIdHandle}
                  value={typeId}
                  isWide={true}
                />
              </label>
              {typeId == "4" ? (
                <label className={s.range}>
                  <span className={s.label}>Событие:</span>
                  <CustomSelect
                    items={activeTypeItems}
                    onChange={referenceIdsHandle}
                    value={referenceIds}
                    isLoading={statsQuery.isLoading}
                    isWide={true}
                    withSearch={true}
                    placeholder="Выберите событие"
                  />
                </label>
              ) : (
                <label className={s.range}>
                  <span className={s.label}>Субъект:</span>
                  <CustomSelect
                    items={activeTypeItems}
                    onChange={referenceIdsHandle}
                    value={referenceIds}
                    isLoading={statsQuery.isLoading}
                    isWide={true}
                    withSearch={true}
                    placeholder="Выберите субъект"
                  />
                </label>
              )}
              <label className={s.range}>
                <span className={s.label}>Источник:</span>
                <CustomSelect
                  items={listSourse}
                  onChange={changeSourse}
                  value={sourse}
                  isLoading={sourcesListIsLoading}
                  isWide={true}
                  placeholder="Выберите источник"
                  withSearch={true}
                />
              </label>
            </>
          )}
        </div>
      </Panel>

      <ApiInfo
        isLoading={dataIsLoading || filterInProgress}
        isError={false}
        error={undefined}
      />
      {!dataIsLoading && !filterInProgress && filteredData?.length < 1 && <NoData />}
      {!dataIsLoading && !filterInProgress && filteredData?.length > 0 && (
        <div className={s.list}>
          {filteredData?.map((item: any, index: number) => (
            <Topic
              key={item?.id + index}
              number={index + 1}
              data={item}
              referenceId={reference_id}
              profileId={profileID}
              networkId={networkID}
              type={type}
            />
          ))}
        </div>
      )}
    </>
  );
};
