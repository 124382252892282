import type { ReactNode, SelectHTMLAttributes } from "react";
import clsx from "clsx";
import arrow from "./arrow.svg";
import s from "./select.module.css";

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  children: ReactNode;
  type?: "solid" | "outline";
  className?: string;
};

export const Select = ({
  children,
  className,
  type = "outline",
  ...attrs
}: Props) => {
  return (
    <div className={clsx(s.selectBox, className, s[type])}>
      <img className={s.arrow} src={arrow} alt="" />
      <select {...attrs}>{children}</select>
    </div>
  );
};
