type Query = {
  isError: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isUninitialized: boolean;
  [key: string]: any;
};

export function mergeQueries(...queries: Query[]) {
  const initializedQueries = queries.filter((item) => !item.isUninitialized);
  const errors = initializedQueries.map((item) => item.error).filter(Boolean);
  const error = errors[0];
  const isError = initializedQueries.some((item) => item.isError);
  const isFetching = initializedQueries.some((item) => item.isFetching);
  const isLoading = initializedQueries.some((item) => item.isLoading);
  const isSuccess = initializedQueries.every((item) => item.isSuccess);

  return {
    errors,
    error,
    isError,
    isFetching,
    isLoading,
    isSuccess,
  };
}
