import {
  toLocaleDate,
  toLocaleDateTime,
  useGetAllPostsQuery,
  useGetAllParamsQuery
} from "../../features";
import { InfoDescription, InfoPanel } from "../../ui";
import s from "./newsbreakItem.module.css";
import {ApiServices} from "../../services/api-services";
import {useEffect, useState} from "react";
import {getNumberOfDays} from "../../utils/get-number-of-days";
import {StatisticsExtended} from "../../ui/statistics-extended/statistics-extended";

type Props = {
  title: string;
  id: number;
  number: number;
  thread_id: string;
  details: any;
};

export function NewsBreakItem({ title, id, number, details, thread_id }: Props) {
  const [riskList, setRiskList] = useState<Array<string>>([])
  const [currentStats, setCurrentStats] = useState<{smi: any, social: any}>({
    smi: null,
    social: null
  })
  const allPosts = useGetAllPostsQuery({})

  useEffect(() => {
    if (details.risk_id?.length > 0) {
      getRisksData(details.risk_id)
    }
    if (details.source_id?.length > 0) {
      getStatsData(details.source_id)
    }
  }, [])

  const getRisksData = async (ids: number[]) => {
    const tempRiskList: string[] = []
    for (const riskId of ids) {
      const riskResponse = await ApiServices.getRiskItem({ id: riskId })
      if (riskResponse.data) {
        tempRiskList.push(riskResponse.data.title)
      }
    }
    setRiskList(tempRiskList)
  }

  const getStatsData = async (ids: number[]) => {
    let tempStats: {smi: any, social: any} = {
      smi: null,
      social: null
    }

    for (const sourceType of ['smi', 'social']) {
      let isEmpty = true
      let currentTypeStats = {
        attendance: 0,
        comments_count: 0,
        federal: 0,
        federal_attendance: 0,
        likes_count: 0,
        local: 0,
        local_attendance: 0,
        negative: 0,
        netural: 0,
        positive: 0,
        post_count: 0,
        regional: 0,
        regional_attendance: 0,
        reposts_count: 0,
        viewed: 0,
      }
      const statsKeys = Object.keys(currentTypeStats)
      for (const threadId of ids) {
        const statsResponse = await ApiServices.getThreadStats({ thread_id: threadId, type: sourceType })
        if (statsResponse.data?.stats) {
          isEmpty = false
          statsKeys.forEach((it: string) => {
            // @ts-ignore
            currentTypeStats[it] += statsResponse.data.stats[it]
          })
        }

      }
      if (!isEmpty) {
        // @ts-ignore
        tempStats[sourceType] = { ...currentTypeStats }
      }
    }
    setCurrentStats(tempStats)
  }

  let retroDate = allPosts?.data?.filter((item:any) => item.id == details.source_id[0])[0]?.retro

  const allParams = useGetAllParamsQuery({"thread_id":details.source_id[0],"days":getNumberOfDays(retroDate)})

  // if (!isSuccess) {
  //   return (
  //     <InfoPanel title="" number={number}>
  //       <ApiInfo isLoading={isLoading} isError={isError} error={error} />
  //     </InfoPanel>
  //   );
  // }

  return (
    <InfoPanel title={title} number={number} statistics={{ publications: 0 }}>
      <div className={s.status}>
        {currentStats.smi || currentStats.social
          ? <StatisticsExtended data={currentStats} /> : null
        }
      </div>
      <InfoDescription
        className={s.description}
        title="Описание:"
        description={details.description}
      />

<InfoDescription title="Ответственный:" description={details.responsible_id?.title} />
      <div className={s.columns}>
      <InfoDescription
        className={s.description}
        title="Ссылка:"
        description={details.url}
      />
        <InfoDescription
          title="Цель:"
          description={details.target || "Отсутствует"}
        />
        <InfoDescription
          title="Региональный проект:"
          description={details.regional_project_id?.map((it: any, idx: number) => {
            return <span key={it.id}>{it.title}</span>
          })}
        />
        <InfoDescription title="Направления стратегии:" description={details.strategy_id?.title || "Отсутствует"} />
        <InfoDescription title="Рекомендация к уровню планирования:" description={details.plan_lvl_id?.title} />
        <InfoDescription title="Сфера:" description={details.sphere_id?.map((it: any, idx: number) => {
          if (idx < details.sphere_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        })}/>
        <InfoDescription title="Целевая аудитория:" description={details.ca_id?.map((it: any, idx: number) => {
          if (idx < details.ca_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        })}/>
        <InfoDescription title="Каналы:" description={details.channel_id?.length > 0 ? details.channel_id?.map((it: any, idx: number) => {
          if (idx < details.channel_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "Отсутствует"}/>
        <InfoDescription
          title="Локация:"
          description={details.location_id?.title}
        />
        <InfoDescription
          title="Медиакампания:"
          description={details.company_id?.title || "Отсутствует"}
        />
        <InfoDescription
          title="Вид планируемого материала:"
          description={details.content_type_id?.title || "Отсутствует"}
        />
        <InfoDescription
          title="Важность:"
          description={details.significance_id?.title || "Отсутствует"}
        />
        <InfoDescription
          title="Бюджет/Ресурсы:"
          description={details.budget || "Отсутствует"}
        />
        <InfoDescription title="Риск:" description={riskList.length > 0 ? riskList.map((it: any, idx: number) => {
          if (idx < riskList.length - 1) {
            return it + ', '
          }
          return it
        }) : "Отсутствует"} />
        <InfoDescription
          title="Дата ключевого события:"
          description={details.date_event !="0000-00-00 00:00:00" ? (details.date_event) : "Отсутствует"}
        />
        <InfoDescription
          title="Дата/время начала:"
          description={(details.date_start) !="0000-00-00 00:00:00" ? (details.date_start) : "Отсутствует"}
        />
        <InfoDescription
          title="Дата/время окончания:"
          description={(details.date_finish) !="0000-00-00 00:00:00" ? (details.date_finish) : "Отсутствует"}
        />
        <InfoDescription
          title="Подпрограмма:"
          description={details.subprogram_id?.map((it: any, idx: number) => {
          return <span key={it.id}>{it.title}</span>
        })}
      />
        <InfoDescription title="Соисполнители:" description={details.coop_id?.length > 0 ? details.coop_id?.map((it: any, idx: number) => {
          if (idx < details.coop_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "Отсутствует"}/>
        <InfoDescription title="Участники:" description={details.member_id?.length > 0 ? details.member_id?.map((it: any, idx: number) => {
          if (idx < details.member_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "Отсутствует"}/>
        <InfoDescription title="Дополнительные участники:" description={details.add_member_id?.length > 0 ? details.add_member_id?.map((it: any, idx: number) => {
          if (idx < details.add_member_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "Отсутствует"}/>
        <InfoDescription title="Автор:" description={details.author || "Отсутствует"} />
        <InfoDescription title="Готовый материал:"
        description={details?.source_id?.length > 0 ? (details?.source_id?.map((it:any)=><a key={it} href={'/materials/'+ it}>{allPosts?.data?.filter((item:any)=>item.id==it)[0]?.threadname ? allPosts?.data?.filter((item:any)=>item.id==it)[0]?.threadname :   it +' '}</a>)) : "Отсутствует"}/>
        <InfoDescription title="ИОГВ:"
        description={details?.iogv_id?.title || "Отсутствует"}/>
      </div>
    </InfoPanel>
  );
}
