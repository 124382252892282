import clsx from "clsx";
import {
  useStatsQuery,
  useRiskListQuery,
  useSmiTopQuery,
  useSocTopQuery,
  useSmiGraphQuery,
  useSocGraphQuery,
  useUnatedGraphQuery,
  useIndicatorsTopQuery,
  useGetAllPostsQuery,
  useNewsbreakListQuery
} from "../../features";
import { Panel, StatsPanel, Table } from "../../ui";
import { Title } from "../../ui/panel/title";
import { Tooltip, Bar, XAxis, YAxis, Legend } from "recharts";
import { BarChart } from "../../ui";

import s from "./statistic.module.css";
import {Scorecard} from "../../ui/scorecard";
import {TopSources} from "../../ui/top-sources";
import {ApiServices} from "../../services/api-services";
import {useEffect, useState} from "react";

type DataItem = {
  id: number;
  title: number;
  items: any[];
};

const getMonitoringCount = (data: DataItem[], id: string) => {
  const arr = data?.find((item: any) => item.id === +id);
  return arr?.items.length;
};

export function Statistic() {
  const [riskCount, setRiskCount] = useState<number>(0)
  const result = useStatsQuery("");
  const subjectsCount = getMonitoringCount(
    result.data,
    process.env.REACT_APP_SUBJECTS_ID as string
  );
  const eventsCount = getMonitoringCount(
    result.data,
    process.env.REACT_APP_EVENTS_ID as string
  );

  const queryData = useRiskListQuery({ start: 0, limit: 0 });
  const allPosts = useGetAllPostsQuery({})
  const infoCount = useNewsbreakListQuery({ start: 0, limit: 0 }).data?.items.length

  useEffect(() => {
    if (queryData?.data?.items) {
      getRisksData()
    }
  }, [queryData])

  const getRisksData = async () => {
    setRiskCount(queryData.data.in_work)
  }

  return (
    <>
      <div className={s.statsGrid}>
        <div className={s.stats}>
          <StatsPanel
            className={s.stat}
            title="Всего субъектов мониторинга"
            count={subjectsCount}
          />
          <StatsPanel
            className={s.stat}
            title="Всего событий мониторинга"
            count={eventsCount}
          />
          <StatsPanel
            className={s.stat}
            title="Всего готовых материалов"
            count={allPosts.data?.length-2}
          />
          <StatsPanel
            className={s.stat}
            title="Количество рисков в работе"
            count={riskCount}
          />
          <StatsPanel
            className={s.stat}
            title="Количество инфоповодов в&nbsp;системе"
            count={infoCount || '-'}
          />
        </div>
      </div>
{/*      <Scorecard id={+process.env.REACT_APP_THREAD_ID!} referenceFilter={[232, 692, 1331, 1332, 1330, 695, 1299, 625, 1222, 698, 704, 1068, 691, 699, 1253, 700, 690, 701, 1139, 702, 629]} />*/}
{/*      <TopSources id={+process.env.REACT_APP_THREAD_ID!} sourceType={'smi'}  referenceFilter={[232, 692, 1331, 1332, 1330, 695, 1299, 625, 1222, 698, 704, 1068, 691, 699, 1253, 700, 690, 701, 1139, 702, 629]} />*/}
{/*      <TopSources id={+process.env.REACT_APP_THREAD_ID!} sourceType={'social'}  referenceFilter={[232, 692, 1331, 1332, 1330, 695, 1299, 625, 1222, 698, 704, 1068, 691, 699, 1253, 700, 690, 701, 1139, 702, 629]} />*/}

      <Scorecard id={5844} />
      <TopSources id={5844} sourceType={'smi'} />
      <TopSources id={5844} sourceType={'social'} />
    </>
  );
}
