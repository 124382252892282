import clsx from "clsx";
import moment from "moment";
import { PostMedia } from "..";
import { TopicPopup, useTopicsModalQuery } from "../../features";
import s from "./summary.module.css";


type Props = {
  data: any;
  number: number;
  className?: string;
  referenceId?: string;
  tonal?: string;
  tonalFilter?: any
  period?: string
};

export const Summ = ({ number, className, data, referenceId, tonal, tonalFilter, period}: Props) => {
  let dateStart = new Date();
  let dateFinish = period == 'month' ? dateStart.setMonth(dateStart.getMonth() - 1) : period == 'week' ? dateStart.setDate(dateStart.getDate() - 6) : period == 'day' ? dateStart.setHours(dateStart.getHours() - 24) : dateStart;  
  const publicationsQuery = useTopicsModalQuery({
    id: data.id.length > 10 ? data.id : data.group_id,
    type: 'smi',
    referenceFilter: [parseInt(referenceId!)],
    from: moment(dateStart)?.format("YYYY-MM-DD") + " 00:00:00",
    to: moment(new Date())?.format('YYYY-MM-DD') + ' 23:59:59'
  });

  const postMediaData = {
    id: data.id,
    group_id: data.group_id,
    topic: data.title,
    subject: data.reference_item,
    level: 0,
    subjectPublications: data.postcount,
    subjectCoverage: data.attendance,
    totalPublications: data.total_posts,
    totalCoverage: data.total_attendance,
    dynamicData: data.total_graph,
    mediaPublications: data.smi_total_posts,
    socialMediaPublications: data.social_total_posts,
    storyDocs: data.storyDocs,
    lastHourDocs: data.lastHourDocs,
    publications: publicationsQuery
  };


  const postNetural = publicationsQuery.data?.items.filter((it: any) => it.trust.trust === 0).length;;
  const postNegative = publicationsQuery.data?.items.filter((it: any) => it.trust.trust === -1).length;;
  const postPositive = publicationsQuery.data?.items.filter((it: any) => it.trust.trust === 1).length;;
  const postSumm = postNetural + postNegative + postPositive;
  tonal = postNegative*100/postSumm >= 25 ? 'trending_negative' : postPositive*100/postSumm >= 25 ? 'trending_positive' : 'neutral'
  const postFederal = publicationsQuery.data?.items.filter((it: any) => it.smi_type === "federal") || [];

  //tonalFilter(tonal)

  const content = (
    <div className={className}>
      {tonalFilter === "" &&
        <PostMedia
          number={0}
          coat={postFederal.length > 0}
          className={clsx(s.topic, s[tonal])}
          data={postMediaData}
        />
      }
      {tonalFilter === tonal &&
        <>
          <PostMedia
            number={0}
            coat={postFederal.length > 0}
            className={clsx(s.topic, s[tonal])}
            data={postMediaData}
          />
        </>
      }
    </div>
  );


  return <TopicPopup content={content} data={postMediaData} referenceId={referenceId} profileId='all'/>;
};
