export type TrustValue = "" | "-1" | "0" | "1" | "unset" | "set";

export const trustMap = {
  "": "unknown",
  "0": "default",
  "-1": "negative",
  "1": "positive",
};

export const trusts = [
  { "": "Любая" },
  { set: "С лояльностью" },
  { unset: "Без лояльности" },
  { "1": "Позитивная" },
  { "-1": "Негативная" },
  { "0": "Нейтральная" },
];
