import { combineReducers } from "@reduxjs/toolkit";
import {
  authReducer,
  monitoringApi,
  topicsApi,
  authApi,
  risksApi,
  topApi,
  newsbreakApi,
  threadsApi,
  publicationsApi,
} from "../features";

export const reducer = combineReducers({
  auth: authReducer,
  [authApi.reducerPath]: authApi.reducer,
  [risksApi.reducerPath]: risksApi.reducer,
  [topApi.reducerPath]: topApi.reducer,
  [newsbreakApi.reducerPath]: newsbreakApi.reducer,
  [topicsApi.reducerPath]: topicsApi.reducer,
  [monitoringApi.reducerPath]: monitoringApi.reducer,
  [threadsApi.reducerPath]: threadsApi.reducer,
  [publicationsApi.reducerPath]: publicationsApi.reducer,
});
