import { useEffect, useState } from "react";

import { Button, PageTitle, Panel, ApiInfo, NoData, Trend } from "../../ui";
import s from "./trending.module.css";
import { ApiServices } from "../../services/api-services";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import { TrendingSbj } from "./trending_sbj";
import moment from "moment";

type SateType = "subject" | "event" | "smi";
type ErrorType = FetchBaseQueryError | SerializedError;

export const Trending = () => {
  const [type, setType] = useState<SateType>("subject");
  const [isShort, setIsShort] = useState<boolean>(false);
  const [isSubject, setIsSubject] = useState<boolean>(false);
  const [items, setItems] = useState([] as any[]);
  const [filteredData, setFilteredData] = useState([] as any[]);
  const [onlyFederal, setOnlyFederal] = useState(false);
  const [isFederal, setIsFederal] = useState<any>([]);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | undefined>(undefined);

  const { REACT_APP_EVENTS_ID, REACT_APP_SUBJECTS_ID } = process.env;
  const reference_id =
    type === "event" ? REACT_APP_EVENTS_ID : REACT_APP_SUBJECTS_ID;

  useEffect(() => {
    getAllData();
  }, [isShort]);
  const updateData = (value:any) => {
    isFederal.push(value)
    setIsFederal(isFederal)
 }

  useEffect(() => {
    items.map((it: any, id:any) =>{
      it["federal"] = isFederal[id];
    })
    setFilteredData(
      onlyFederal ? items?.filter((it: any) => it?.federal > 0) : items
    );
  }, [onlyFederal, items]);

  const getAllData = async () => {
    let ratingResponse: { data: any; error: any };

    if (isShort) {
      ratingResponse = await ApiServices.getTopicRatingShort();
    } else {
      ratingResponse = await ApiServices.getTopicRating();
    }

    if (ratingResponse.data) {
      const tempTopicRatingList = [];

      for (const topicRating of ratingResponse.data) {
        let dateStart = new Date();
        let dateFinish = dateStart.setHours(dateStart.getHours() - 24); 
        const topicResponse = await ApiServices.getTopic({
          id:
            topicRating.id.length > 10 ? topicRating.id : topicRating.group_id,
          type: "all",
          referenceFilter: [parseInt(reference_id!)],
          from: moment(dateStart)?.format("YYYY-MM-DD HH:MM"),
          to: moment(new Date())?.format('YYYY-MM-DD HH:MM')
        });
        tempTopicRatingList.push({
          ...topicRating,
          details: topicResponse.data,
        });
      }

      setItems(
        tempTopicRatingList.sort((a: any, b: any) =>
          a.details.postcount < b.details.postcount ? 1 : -1
        )
      );
      setIsFetching(false);
      setIsSuccess(true);
      setIsError(false);
      setError(undefined);
    }
    if (ratingResponse.error) {
      setIsFetching(false);
      setIsSuccess(false);
      setIsError(true);
      setError(ratingResponse.error);
    }
  };

  const lastHourSet = () => {
    setIsSubject((prevState) => !prevState);
    setIsShort(false);
  };
  const subjectSet = () => {
    setIsSubject((prevState) => !prevState);
    setIsShort(true);
  };

  return (
    <>
      <PageTitle>Быстрорастущие темы</PageTitle>
      <Panel padding>
        {!isSubject ? (
          <>
            <Button
              className={s.tab}
              active={isShort}
              onClick={() => setIsShort((prevState) => !prevState)}
            >
              Быстрорастущие темы за последние 3 часа
            </Button>
            <Button
              className={s.fedtab}
              active={onlyFederal}
              onClick={() =>setOnlyFederal((prevState) => !prevState)}
            >
              Федеральная повестка
            </Button>
          </>
        ) : (
          <Button
            className={s.tab}
            active={!isShort}
            onClick={() => lastHourSet()}
          >
            Быстрорастущие темы
          </Button>
        )}
        <Button
          className={s.tab}
          active={isSubject}
          onClick={() => subjectSet()}
        >
          Быстрорастущие темы по субъектам
        </Button>
      </Panel>
      {!isSubject ? (
        <>
          <ApiInfo isLoading={isFetching} isError={isError} error={error} />
          {isSuccess && filteredData.length < 1 && <NoData />}
          {isSuccess && filteredData.length > 0 && (
            <div className={s.list}>
              {filteredData.map((item: any, index: number) => (
                <Trend
                  key={item.id}
                  number={index + 1}
                  data={item}
                  referenceId={reference_id}
                  onlyFederal={onlyFederal}
                  updateData={updateData}
                />
              ))}
            </div>
          )}
        </>
      ) : (
        <TrendingSbj />
      )}
    </>
  );
};
