import clsx from "clsx";
import s from "./topic-description.module.css";

type Props = {
  title: string;
  description?: string | number;
  coverage?: number;
  plus?: number;
  className?: string;
};

export const TopicDescription = (props: Props) => {
  const { className, title, description, coverage, plus } = props;
  return (
    <p className={clsx(s.topicDescription, className)}>
      {title}:{" "}
      <span>
        {typeof description === "number"
          ? description.toLocaleString()
          : description ?? "–"}
      </span>
      {coverage
        ? ", охват: "
        : null
      }
      {coverage
        ? <span>{coverage.toLocaleString()}</span>
        : null
      }
      {plus
        ? <sup>+</sup>
        : null
      }
      {plus
        ? <sup>{plus.toLocaleString()}</sup>
        : null
      }
    </p>
  );
};
