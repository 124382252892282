import type { ReactNode } from "react";
import { BarChart as ReBarChart, ResponsiveContainer } from "recharts";

type Props = {
  data: any;
  height: number;
  children: ReactNode;
  className?: string;
};

export function BarChart({ data, height, children, className }: Props) {
  // ResponsiveContainer will be used here later
  return (
    <ResponsiveContainer className={className} width="100%" height={height}>
      <ReBarChart
        width={730}
        height={250}
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        // may be useful for design
        // barCategoryGap={10}
      >
        {children}
      </ReBarChart>
    </ResponsiveContainer>
  );
}
