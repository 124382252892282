import clsx from "clsx";
import { Panel } from "../panel";
import { Statistics } from "../statistics";
import news from "./img/icon-source.svg";
import iconTelegram from "./img/icon-telegram.svg";
import iconFacebook from "./img/icon-facebook.svg";
import iconInstagram from "./img/icon-instagram.svg";
import iconVk from "./img/icon-vk.svg";
import iconTwitter from "./img/icon-twitter.svg";
import iconYouTube from "./img/icon-youtube.svg";
import fallback from "./img/fallback.svg";
import s from "./post.module.css";
import { useEffect, useState } from "react";
import { Button } from "../button";
//import  Image  from '../../ui/image/index'
//import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { CaretRightOutlined } from "@ant-design/icons";
import { CreateEvent } from "../create-event";

export type Author = {
  name: string;
  avatar: string;
  url: string;
};

export type Statistic = {
  people: number;
  views: number;
  likes: number;
  comments: number;
  reposts: number;
};

export type Media = {
  images: any;
  video: any;
};

export type PostProps = {
  id: string;
  title: string;
  author: Author;
  text: string;
  url?: string;
  uri?: string;
  coat: boolean;
  smiType?: string,
  date: Date;
  statistics: Statistic;
  className?: string;
  type?: string;
  media: Media;
  networkName?: string;
  onCreateEvent?: (data: any) => void;
};

const dateOptions: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "numeric",
  year: "2-digit",
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export function Post(props: PostProps) {
  const {
    id,
    className,
    title,
    author,
    text,
    url,
    uri,
    type,
    statistics,
    smiType,
    coat,
    date,
    media,
    networkName,
    onCreateEvent,
  } = props;

  const [showFullText, setShowFullText] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const shortImages = media.images.slice(0, 3);

  const regex = /(<([^>]+)>)/gi;
  const safeText = text.replace(regex, "");
  const slicedText =
    safeText.length > 300 ? safeText.slice(0, 460) + "…" : safeText;

  const smallItemStyles: React.CSSProperties = {
    cursor: "pointer",
    objectFit: "cover",
    width: "100px",
    height: "70px",
  };

  let sourceIcon: string;
  switch (networkName) {
    case "tg":
      sourceIcon = iconTelegram;
      break;
    case "fb":
      sourceIcon = iconFacebook;
      break;
    case "ig":
      sourceIcon = iconInstagram;
      break;
    case "tw":
      sourceIcon = iconTwitter;
      break;
    case "vk":
      sourceIcon = iconVk;
      break;
    case "yt":
      sourceIcon = iconYouTube;
      break;
    default:
      sourceIcon = news;
  }

  let getSmiType: string;
  switch (smiType) {
    case "local":
      getSmiType = 'местный';
      break;
    case "federal":
      getSmiType = 'федеральный';
      break;
    case "regional":
      getSmiType = 'региональный';
      break;
    default:
      getSmiType = 'местный';
  }

  // const createEventHandle = () => {
  //   if (onCreateEvent && url) {
  //     onCreateEvent({
  //       url,
  //       text,
  //     });
  //   }
  // };

  return (
    <Panel className={clsx(className, s.panel)} coat={coat}>
      <div
        className={clsx(s.post, {
          [s.default]: type === "default",
          [s.negative]: type === "negative",
          [s.positive]: type === "positive",
        })}
      >
        <div className={s.title}>
          <a href={author.url} className={s.origin}>
            <div className={s.icons}>
              <img
                className={s.avatar}
                src={author.avatar}
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = fallback;
                }}
              />
              <img className={s.avatarIcon} src={sourceIcon} alt="" />
            </div>
            
            <div className={s.info}>
              <p className={s.site}>{author.name}</p>
              <p className={s.date}>
                {date.toLocaleDateString("ru", dateOptions)}
                &nbsp; | &nbsp;
                {date.toLocaleTimeString("ru", timeOptions)}
              </p>
            </div>
            <p>&nbsp; | &nbsp;{networkName == 'gs' ? "СМИ" : "Соц. сети"} {networkName == 'gs' ? getSmiType : '' }</p>
          </a>
          
          <Statistics
            className={s.statistics}
            data={statistics}
            networkName={networkName}
          />
        </div>
        <div className={s.textcontain}>
          <div className={s.uri}>
            <p className={s.headline}>{title ?? "–"}</p>
            <p className={s.text}>
              {safeText.length > 500 && !showFullText ? (
                <p className={s.storttext}>{slicedText} </p>
              ) : (
                safeText
              )}{" "}
            </p>
          </div>
          <p className={s.show}>
            {safeText.length > 500 && !showFullText ? (
              <span
                className={s.showMore}
                onClick={() => setShowFullText(true)}
              >
                Читать далее
              </span>
            ) : null}
          </p>
        </div>
        <div className={s.media}>
          {media.images.length > 0 && (
            <Gallery>
              <div
                style={{
                  display: "flex",
                  gridTemplateColumns: "auto-track-list",
                  gridTemplateRows: "70px",
                  gridGap: 10,
                  marginTop: "10px",
                }}
              >
                {media.images.length > 3 && !showAllImages
                  ? shortImages?.map((item: { url: string | undefined }, key: number) => (
                      <Item
                        original={item.url}
                        thumbnail={item.url}
                        width="1600"
                        height="1000"
                        alt={item.url}
                        key={key}
                      >
                        {({ ref, open }) => (
                          <img
                            style={smallItemStyles}
                            src={item.url}
                            ref={
                              ref as React.MutableRefObject<HTMLImageElement>
                            }
                            onClick={open}
                          />
                        )}
                      </Item>
                    ))
                  : media?.images?.map((item: { url: string | undefined }, key: number) => (
                      <Item
                        original={item.url}
                        thumbnail={item.url}
                        width="1600"
                        height="1000"
                        alt={item.url}
                        key={key}
                      >
                        {({ ref, open }) => (
                          <img
                            style={smallItemStyles}
                            src={item.url}
                            ref={
                              ref as React.MutableRefObject<HTMLImageElement>
                            }
                            onClick={open}
                          />
                        )}
                      </Item>
                    ))}
              </div>
            </Gallery>
          )}

          {media.images.length > 3 && !showAllImages && (
            <div
              className={s.more}
              onClick={() => setShowAllImages((prevState) => !prevState)}
            >
              Ещё {media.images.length - 3} фото
            </div>
          )}

          {media.video.length > 0 &&
            media.video.map((item: { url: string | undefined; image: any }) => (
              <a className={s.video} href={item?.url} target="_blank">
                <img width={100} src={item?.image} />
                <i className={s.videoicon}>
                  <CaretRightOutlined />
                </i>
              </a>
            ))}
        </div>
        <div className={s.footer}>
          <a href={uri} target="_blank" rel="noreferrer" className={s.footerBtn}>Перейти на публикацию</a>
          {onCreateEvent ? (
            <CreateEvent data={uri} number={0} />
          ) : null}
        </div>
      </div>
    </Panel>
  );
}
