import type { ReactNode } from "react";
import clsx from "clsx";
import styles from "./page-title.module.css";

type Props = {
  children: ReactNode;
  className?: string;
};

export const PageTitle = ({ children, className }: Props) => {
  return <h1 className={clsx(styles.pageTitle, className)}>{children}</h1>;
};
