import { Panel } from "../panel";
import s from "./top-sources.module.css";
import { Title } from "../panel/title";
import { BarChart } from "../bar-chart";
import { Bar, Tooltip, XAxis, YAxis } from "recharts";
import { useEffect, useState } from "react";
import { ApiServices } from "../../services/api-services";
import CustomSelect from "../custom-select/custom-select";
import { Loader } from "../loader";
import { DateRangePicker, DateTuple } from "../date-range-picker";
import moment from "moment";
import { useLastDateQuery, usePeriodGetQuery } from "../../features";

type Props = {
  id: number;
  sourceType: "smi" | "social";
  referenceFilter?: number[];
  onNameClick?: (value: string) => void;
};

export const TopSources = ({
  id,
  sourceType,
  referenceFilter = undefined,
  onNameClick = undefined,
}: Props) => {
  const [publicationsIsActive, setPublicationsIsActive] = useState(true);
  const [commentsIsActive, setCommentsIsActive] = useState(true);
  const [period, setPeriod] = useState<string>("custom");
  const periodGetQuery = usePeriodGetQuery({ thread_id: String(id) });
  const lastDateQuery = useLastDateQuery({
    thread_id: String(id),
    from: periodGetQuery?.data?.periods?.retro_min,
    to: periodGetQuery?.data?.periods?.retro_max,
  });
  const { data, isSuccess, isLoading } = lastDateQuery;
  const [customPeriod, setCustomPeriod] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: new Date(),
    to: new Date(),
  });
  const [smiType, setSmiType] = useState<string>("any");
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [sourceList, setSourceList] = useState<
    Array<{ id: string; keyword: string }>
  >([]);
  const [selectedSource, setSelectedSource] = useState<string>("");
  const [graphData, setGraphData] = useState<Array<any>>([]);
  const [unionGraphData, setUnionGraphData] = useState<Array<any>>([]);
  const [graphDataIsLoading, setGraphDataIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (data && data.date != false && isSuccess) {
      setCustomPeriod({
        from: new Date(
          +data?.date?.slice(0, 4),
          +data?.date?.slice(5, 7) - 1,
          +data?.date?.slice(8, 10)
        ),
        to: new Date(
          +data?.date?.slice(0, 4),
          +data?.date?.slice(5, 7) - 1,
          +data?.date?.slice(8, 10)
        ),
      });
    }
  }, [data]);

  useEffect(() => {
    getAllTopData();
  }, [period, smiType, customPeriod]);

  useEffect(() => {
    if (customPeriod.from && customPeriod.to) {
      getAllTopData();
    }
  }, [customPeriod]);

  const getAllTopData = async () => {
    setGraphDataIsLoading(true);
    const topResponse = await ApiServices.getOwnersTopByPostCount({
      thread_id: id,
      type: sourceType,
      period: period !== "custom" ? period : undefined,
      smi_type: sourceType === "smi" ? smiType : undefined,
      from:
        period === "custom"
          ? moment(customPeriod.from)?.format("YYYY-MM-DD") + " 00:00:00"
          : undefined,
      to:
        period === "custom"
          ? moment(customPeriod.to)?.format("YYYY-MM-DD") + " 23:59:59"
          : undefined,
      referenceFilter,
    });
    if (topResponse?.data) {
      setSourceList(
        topResponse.data.items.map((it: any) => ({
          id: it.inner_id,
          keyword: it.title,
        }))
      );
      setTableData(topResponse.data.items);
    }
    setGraphDataIsLoading(false);
  };

  useEffect(() => {
    calculateUnionGraphData();
  }, [tableData, customPeriod]);

  useEffect(() => {
    filterGraphData();
  }, [tableData, selectedSource, customPeriod]);

  const calculateUnionGraphData = () => {
    setGraphDataIsLoading(true);
    if (tableData?.length) {
      let result: any[] = tableData[0].graph.map((item: any) => ({
        item_date: item.item_date,
        post_count: 0,
        comments_count: 0,
      }));

      for (const item of tableData) {
        item.graph.forEach((it: any, idx: number) => {
          result[idx].post_count = result[idx].post_count + it.post_count;
          result[idx].comments_count =
            result[idx].comments_count + it.comments_count;
        });
      }

      setUnionGraphData(result);
    }
    setGraphDataIsLoading(false);
  };

  const filterGraphData = () => {
    setGraphDataIsLoading(true);
    if (tableData) {
      let resultGraphData: any[] = [];

      if (selectedSource !== "") {
        let tempGraphData: any[] = [];
        const selectedItem = tableData.find(
          (it: any) => it.inner_id === selectedSource
        );
        if (selectedItem) {
          tempGraphData = selectedItem.graph.slice();
        }
        resultGraphData = tempGraphData.slice();
      } else {
        resultGraphData = unionGraphData.slice();
      }

      setGraphData(
        resultGraphData.map((it: any) => {
          let name = it.item_date.split(" ")[1];
          if (period === "week") {
            name =
              it.item_date.split("-")[2] + "-" + it.item_date.split("-")[1];
          }
          if (period === "month") {
            name = it.item_date.split("-")[2];
          }
          if (period === "custom") {
          }
          return {
            name: name,
            Комментарии: it.comments_count,
            Публикации: it.post_count || 0,
          };
        }) || []
      );
    }
    setGraphDataIsLoading(false);
  };

  return (
    <>
      {tableData?.length > 0 && (
        <div className={s.tables}>
          <Panel className={s.table}>
            <div className={s.header}>
              <Title
                className={s.title}
                caption={`Топ источников ${
                  sourceType === "smi" ? "СМИ" : "соц.сети"
                }`}
              />
              <div className={s.selectors}>
                {sourceType === "smi" ? (
                  <CustomSelect
                    items={[
                      { id: "any", keyword: "Все" },
                      { id: "federal", keyword: "Федеральные" },
                      { id: "regional", keyword: "Региональные" },
                      { id: "local", keyword: "Местные" },
                    ]}
                    onChange={(value) => setSmiType(String(value))}
                    value={smiType}
                    type="interval"
                  />
                ) : null}
                <CustomSelect
                  items={[
                    { id: "day", keyword: "День" },
                    { id: "week", keyword: "Неделя" },
                    { id: "month", keyword: "Месяц" },
                    { id: "custom", keyword: "Период" },
                  ]}
                  onChange={(value) => setPeriod(String(value))}
                  value={period}
                  type="interval"
                />
              </div>
            </div>
            <div className={s.subHeader}>
              {period === "custom" ? (
                <DateRangePicker
                  className={s.subtitleDate}
                  startDate={customPeriod.from}
                  endDate={customPeriod.to}
                  onChange={([startDate, endDate]: DateTuple) => {
                    setCustomPeriod({
                      from: startDate,
                      to: endDate,
                    });
                  }}
                />
              ) : null}
            </div>
            {graphDataIsLoading ? (
              <div className={s.loaderContainer}>
                <Loader />
              </div>
            ) : (
              <table className={s.topTable}>
                <thead className={s.topTableHead}>
                  <tr className={s.topTableRow}>
                    <td className={s.topTableColWide}>Название</td>
                    <td className={s.topTableCol}>Всего</td>
                    <td className={s.topTableCol}>Поз.</td>
                    <td className={s.topTableCol}>Нейт.</td>
                    <td className={s.topTableCol}>Негат.</td>
                  </tr>
                </thead>
                <tbody className={s.topTableBody}>
                  {tableData.map((item: any) => (
                    <tr
                      className={s.topTableRow}
                      key={item.url + item.inner_id}
                    >
                      <td className={s.topTableColWide}>
                        <a
                          href={`/publications?it_filter=${item.url}`}
                          className={s.topTableName}
                          onClick={(evt) => {
                            if (onNameClick) {
                              evt.preventDefault();
                              onNameClick(item.url);
                            }
                          }}
                        >
                          {item.title}
                        </a>
                        <a
                          href={item.url}
                          className={s.topTableLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.url}
                        </a>
                      </td>
                      <td className={s.topTableCol}>{item.post_count}</td>
                      <td className={s.topTableCol}>{item.positive}</td>
                      <td className={s.topTableCol}>{item.netural}</td>
                      <td className={s.topTableCol}>{item.negative}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Panel>
          <Panel className={s.panel} padding>
            <div className={s.chartHead}>
              <span className={s.chartTitle}>
                Динамика публикаций по ТОП источников{" "}
                {sourceType === "smi" ? "СМИ" : "Соц сетей"}
              </span>
              <CustomSelect
                items={[{ id: "", keyword: "Все" }, ...sourceList]}
                onChange={(value) => setSelectedSource(String(value))}
                value={selectedSource}
                type="interval"
              />
            </div>
            {graphDataIsLoading ? (
              <div className={s.loaderContainer}>
                <Loader />
              </div>
            ) : (
              <BarChart data={graphData} height={299}>
                {publicationsIsActive ? (
                  <Bar dataKey="Публикации" fill="#4EC0E4" />
                ) : null}
                {commentsIsActive ? (
                  <Bar dataKey="Комментарии" fill="#8ACE21" />
                ) : null}
                <XAxis
                  angle={-70}
                  dataKey="name"
                  textAnchor="end"
                  interval={0}
                  height={86}
                />
                <YAxis />
                <Tooltip />
              </BarChart>
            )}
            <div className={s.chartFooter}>
              <button
                className={
                  publicationsIsActive ? s.btnPublicActive : s.btnPublic
                }
                onClick={() =>
                  setPublicationsIsActive((prevState) => !prevState)
                }
              >
                Публикации
              </button>
              <button
                className={commentsIsActive ? s.btnCommentActive : s.btnComment}
                onClick={() => setCommentsIsActive((prevState) => !prevState)}
              >
                Комментарии
              </button>
            </div>
          </Panel>
        </div>
      )}
    </>
  );
};
