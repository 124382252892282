import type { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux";

type Props = {
  children: ReactNode;
};

export function RequireAuthRoute({ children }: Props) {
  let location = useLocation();
  const isAuth = useAppSelector((state) => Boolean(state.auth.userId));

  if (isAuth) return <>{children}</>;
  return <Navigate to="/login" state={{ from: location }} replace />;
}
