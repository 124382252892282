import {toLocaleDateTime, useRiskItemQuery, useThreadItemQuery} from "../../features";
import { ApiInfo, InfoDescription, InfoPanel, Panel } from "../../ui";
import s from "./material.module.css";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {ApiServices} from "../../services/api-services";
import {StatisticsExtended} from "../../ui/statistics-extended/statistics-extended";

type Props = {
  title: string;
  id: string;
  number: number;
  details: any;
  retro_max?: string
  retro?: string
};

export function Material({ title, id, number, details, retro_max, retro }: Props) {
  const [currentStats, setCurrentStats] = useState<{smi: any, social: any}>({
    smi: null,
    social: null
  })

  useEffect(() => {
    getStatsData()
  }, [])

  const getStatsData = async () => {
    let tempStats: {smi: any, social: any} = {
      smi: null,
      social: null
    }

    for (const sourceType of ['smi', 'social']) {
      const statsResponse = await ApiServices.getThreadStats({ thread_id: details.thread_id, type: sourceType, from: retro, to: retro_max })
      console.log('details', retro_max)
      if (statsResponse.data?.stats) {
        // @ts-ignore
        tempStats[sourceType] = { ...statsResponse.data.stats }
      }
    }
    setCurrentStats(tempStats)
  }

  // if (!isSuccess) {
  //   return (
  //     <InfoPanel title="" number={number}>
  //       <ApiInfo isLoading={isLoading} isError={isError} error={error} />
  //     </InfoPanel>
  //   );
  // }

  if (details.thread_id === 0) {
    return null
  }

  return (
    <>
      <InfoPanel
        title={details.title}
        number={number}
        statistics={{ publications: 0, status: "-" }}
      >
        <div className={s.status}>
          {currentStats.smi || currentStats.social
            ? <StatisticsExtended data={currentStats} /> : null
          }
        </div>
        <div className={s.columns}>
          <div>
            {/*<InfoDescription*/}
            {/*  className={s.description}*/}
            {/*  title="Название:"*/}
            {/*  description={details.title || "-"}*/}
            {/*/>*/}
            <InfoDescription
              className={s.description}
              title="Описание:"
              description={details.description || "-"}
            />
          </div>

        </div>
        <div className={s.columns}>
        <InfoDescription
            title="Подпрограмма:"
            description={details.subroutine?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Региональный проект:"
            description={details.regin_project?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Направления стратегии:"
            description={details.stategy?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Сфера:"
            description={details.sphere?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Каналы:"
            description={details.channels?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Локация:"
            description={details.location?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            title="Целевая аудитория:"
            description={details.ca?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            title="Дата/время начала:"
            description={details.date_finish === "0000-00-00 00:00:00" ? "-" : toLocaleDateTime(details.date_start)}
          />
          <InfoDescription
            title="Дата/время окончания:"
            description={details.date_finish === "0000-00-00 00:00:00" ? "-" : toLocaleDateTime(details.date_finish)}
          />
          <InfoDescription title="Ответственный:" description={details.responsible} />
          <InfoDescription title="Соисполнители:" description={details.sub_responsible?.length > 0 ? details.sub_responsible?.map((it: string, idx: number) => {
            if (idx < details.sub_responsible.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Участники:" description={details.members?.length > 0 ? details.members?.map((it: string, idx: number) => {
            if (idx < details.members.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Дополнительные участники:" description={details.ext_members?.length > 0 ? details.ext_members?.map((it: string, idx: number) => {
            if (idx < details.ext_members.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Автор:" description={details.author} />
          <InfoDescription title="Хэштеги:" description={details.hashtag?.length > 0 ? details.hashtag?.map((it: string, idx: number) => {
            if (idx < details.hashtag.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Риски:" description={details.risks?.length > 0 ? details.risks?.map((it: string, idx: number) => {
            if (idx < details.risks.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription
            className={s.description_ref}
            title="Ссылка:"
            description={details.url.length > 0 ? <a target="_blank" href={details.url}>{details.url}</a> : "-"}
          />
          <InfoDescription
            className={s.description_ref}
            title="ИОГВ:"
            description={details.author_iogv || "-"}
          />
          <InfoDescription
            className={s.description_ref}
            title="Материал для СМИ:"
            description={details.title}
          />
        </div>
        <div className={s.footer}>
          <Link className={s.link} to={`${id}`}>Перейти</Link>
        </div>
      </InfoPanel>
    </>
  );
}
