import { useParams } from "react-router-dom";
import { toLocaleDateTime, useThreadItemQuery } from "../../../features";
import { InfoPanel, PageTitle, InfoDescription, ApiInfo } from "../../../ui";
import s from "./material-info.module.css";
import { useEffect, useState } from "react";
import { ApiServices } from "../../../services/api-services";
import { Scorecard } from "../../../ui/scorecard";
import { TopSources } from "../../../ui/top-sources";
import { MaterialInfoPublications } from "./material-info-publications";

export const MaterialInfo = () => {
  let params = useParams();
  const [sourcesList, setSourcesList] = useState([] as any[]);
  const [sourcesListIsLoading, setSourcesListIsLoading] = useState(true);
  const [selectedSource, setSelectedSource] = useState<string>('');

  const queryData = useThreadItemQuery({ thread_id: +params.id! });
  const { data, isSuccess, isLoading } = queryData;

  useEffect(() => {
    getAllData()
  }, [])

  const getAllData = async () => {
    setSourcesListIsLoading(true)
    const sourcesListResponse = await ApiServices.getAllMembers({
      thread_id: params.id,
      offset: 0,
      limit: 10000,
      sources_only: true
    })
    if (sourcesListResponse?.data) {
      setSourcesList(sourcesListResponse.data.members.map((it: any) => ({
        url: it.url,
        profile_id: it.profile_id,
        network_id: it.network_id,
        title: it.username
      })))
    }
    setSourcesListIsLoading(false)
  }

  if (!isSuccess) {
    return (
      <InfoPanel title="">
        <ApiInfo isLoading={isLoading} isError={false} error={undefined} />
      </InfoPanel>
    );
  }

  return (
    <>
      <PageTitle>Готовые материалы</PageTitle>

      <InfoPanel
        className={s.infopanel}
        title={queryData.data?.title}
      >
      <div className={s.columns}>
          <div>
            <InfoDescription
              className={s.description}
              title="Описание:"
              description={data?.description || "-"}
            />
          </div>

        </div>
        <div className={s.columns}>
        <InfoDescription

            title="Подпрограмма:"
            description={data.subroutine?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Региональный проект:"
            description={data.regin_project?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Направления стратегии:"
            description={data.stategy?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Сфера:"
            description={data.sphere?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Каналы:"
            description={data.channels?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Локация:"
            description={data.location?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            title="Целевая аудитория:"
            description={data.ca?.map((it: string, key: number) => {
              return <span key={key}>{it}</span>
            })}
          />
          <InfoDescription
            title="Дата/время начала:"
            description={data.date_finish === "0000-00-00 00:00:00" ? "-" : toLocaleDateTime(data.date_start)}
          />
          <InfoDescription
            title="Дата/время окончания:"
            description={data.date_finish === "0000-00-00 00:00:00" ? "-" : toLocaleDateTime(data.date_finish)}
          />
          <InfoDescription title="Ответственный:" description={data.responsible} />
          <InfoDescription title="Соисполнители:" description={data.sub_responsible?.length > 0 ? data.sub_responsible?.map((it: string, idx: number) => {
            if (idx < data.sub_responsible.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Участники:" description={data.members?.length > 0 ? data.members?.map((it: string, idx: number) => {
            if (idx < data.members.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Дополнительные участники:" description={data.ext_members?.length > 0 ? data.ext_members?.map((it: string, idx: number) => {
            if (idx < data.ext_members.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Автор:" description={data.author} />
          <InfoDescription title="Хэштеги:" description={data.hashtag?.length > 0 ? data.hashtag?.map((it: string, idx: number) => {
            if (idx < data.hashtag.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Риски:" description={data.risks?.length > 0 ? data.risks?.map((it: string, idx: number) => {
            if (idx < data.risks.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription
            className={s.description_ref}
            title="Ссылка:"
            description={data.url.length > 0 ? <a target="_blank" href={data.url}>{data.url}</a> : "-"}
          />
          <InfoDescription title="ИОГВ:" description={data.author_iogv} />
          <InfoDescription
            className={s.description_ref}
            title="Материал для СМИ:"
            description={data.title}
          />
        </div>
      </InfoPanel>

      <Scorecard id={+params.id!} sourcesId={data.sources_id} />
      <TopSources id={+params.id!} sourceType={'smi'} onNameClick={(value: string) => setSelectedSource(value)} />
      <TopSources id={+params.id!} sourceType={'social'} onNameClick={(value: string) => setSelectedSource(value)} />

      <MaterialInfoPublications
        id={params.id || ''}
        sourcesList={sourcesList}
        sourcesListIsLoading={sourcesListIsLoading}
        selectedSource={selectedSource}
      />
    </>
  );
}
