import { createSlice } from "@reduxjs/toolkit";

export type AuthState = { userId: string | null };

const initialState: AuthState = {
  userId: localStorage.userId ?? null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logIn: (state, { payload }) => {
      state.userId = payload;
    },
    logOut: (state) => {
      state.userId = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { logIn, logOut } = authSlice.actions;
export const authReducer = authSlice.reducer;
