import type { InputHTMLAttributes } from "react";
import clsx from "clsx";
import s from "./checkbox.module.css";

type Props = {
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function Checkbox({ className, ...rest }: Props) {
  return (
    <div className={clsx(s.box, className)}>
      <input className={s.active} type="checkbox" {...rest} />
      <span className={s.checkbox}></span>
    </div>
  );
}
