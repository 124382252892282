import posts from "../statistics/img/icon-posts.svg";
import people from "../statistics/img/icon-people.svg";
import views from "../statistics/img/icon-views.svg";
import likes from "../statistics/img/icon-likes.svg";
import comments from "../statistics/img/icon-comments.svg";
import reposts from "../statistics/img/icon-reposts.svg";
import coatImg from "./gerb.jpg";
import s from './statistics-extended.module.css'

export type StatisticExtendedType = {
  attendance: number,
  comments_count: number,
  federal: number,
  federal_attendance: number,
  likes_count: number,
  local: number,
  local_attendance: number,
  negative: number,
  netural: number,
  positive: number,
  post_count: number,
  regional: number,
  regional_attendance: number,
  reposts_count: number,
  viewed: number,
};

type Props = {
  data: {
    smi: StatisticExtendedType;
    social: StatisticExtendedType;
  }
};

export const StatisticsExtended = ({ data }: Props) => {
  return (
    <div className={s.stats}>
      {data.social
        ? (
          <div className={s.statsWrap}>
            <div className={s.statsTitle}>Статистика распространения через Социальные сети</div>
            <div className={s.statsRow}>
              <div className={s.statsItem}>
                <div className={s.statsImageBox}>
                  <img className={s.statsItemImage} src={posts} alt="" />
                </div>
                <p className={s.statsCounter}>{data.social.post_count ?? "Отсутствует"}</p>
              </div>
              <div className={s.statsItem}>
                <div className={s.statsImageBox}>
                  <img className={s.statsItemImage} src={people} alt="" />
                </div>
                <p className={s.statsCounter}>{data.social.attendance}</p>
              </div>
              <div className={s.statsItem}>
                <div className={s.statsImageBox}>
                  <img className={s.statsItemImage} src={views} alt="" />
                </div>
                <p className={s.statsCounter}>{data.social.viewed}</p>
              </div>
              <div className={s.statsItem}>
                <div className={s.statsImageBox}>
                  <img className={s.statsItemImage} src={likes} alt="" />
                </div>
                <p className={s.statsCounter}>{data.social.likes_count}</p>
              </div>
              <div className={s.statsItem}>
                <div className={s.statsImageBox}>
                  <img className={s.statsItemImage} src={comments} alt="" />
                </div>
                <p className={s.statsCounter}>{data.social.comments_count}</p>
              </div>
              <div className={s.statsItem}>
                <div className={s.statsImageBox}>
                  <img className={s.statsItemImage} src={reposts} alt="" />
                </div>
                <p className={s.statsCounter}>{data.social.reposts_count}</p>
              </div>
            </div>
          </div>
        )
        : null
      }

      {data.smi
        ? (
          <div className={s.statsWrap}>
            <div className={s.statsTitle}>Статистика распространения через СМИ</div>
            <div className={s.statsRow}>
              <div className={s.statsItem}>
                <div className={s.statsImageBox}>
                  <img className={s.statsItemImage} src={posts} alt="" />
                </div>
                <p className={s.statsCounter}>{data.smi.post_count ?? "Отсутствует"}</p>
              </div>
              <div className={s.statsItem}>
                <div className={s.statsImageBox}>
                  <img className={s.statsItemImage} src={people} alt="" />
                </div>
                <p className={s.statsCounter}>{data.smi.attendance}</p>
              </div>
              <div className={s.statsItem}>
                <div className={s.statsImageBox}>
                  <img className={s.statsItemImage} src={coatImg} alt="" />
                </div>
                <p className={s.statsCounter}>{data.smi.federal}</p>
              </div>
              <div className={s.statsItem}>
                <div className={s.statsImageBox}>
                  <img className={s.statsItemImage} src={people} alt="" />
                </div>
                <p className={s.statsCounter}>{data.smi.federal_attendance}</p>
              </div>
            </div>
          </div>
        )
        : null
      }
    </div>
  )
}
