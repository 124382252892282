import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogInMutation } from "../../features";
import { ApiInfo, Button, Field, Panel } from "../../ui";
import s from "./login.module.css";

// sergey@glassen-it.com
// 112233

const testData = {
  login:
    process.env.NODE_ENV === "development"
      ? "test_system_glassen@gmail.com"
      : "",
  password: process.env.NODE_ENV === "development" ? "Qwerty1357901" : "",
};

export function Login() {
  //console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
  let navigate = useNavigate();
  const [login, setLogin] = useState(testData.login);
  const [password, setPassword] = useState(testData.password);
  const [fetchAuth, response] = useLogInMutation();
  const { isSuccess, isLoading, isError, error } = response;

  function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    fetchAuth({ login, password });
  }

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess]);

  return (
    <div className={s.container}>
      <Panel className={s.panel} padding>
        <form className={s.form} onSubmit={handleSubmit}>
          {!isLoading && (
            <>
              <Field
                required
                placeholder="Логин"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
              <Field
                type="password"
                placeholder="Пароль"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button active>Log In</Button>
            </>
          )}
          <ApiInfo
            errorClassName={s.error}
            isLoading={isLoading}
            isError={isError}
            error={error}
          />
        </form>
      </Panel>
    </div>
  );
}
